import Mynavbar from './components/mynavbar/mynavbar';
import Mycontactbar from './components/contactbar/mycontactbar';
import MainContent from './components/maincontent/maincontent';
import './App.css';

function App() {
  return (
    <>
    <Mynavbar></Mynavbar>
    <div className="container-fluid">
      
      <Mycontactbar></Mycontactbar>
      <div className="container">
        <MainContent></MainContent>
      </div>
      
    </div>
   </>
  );
}

export default App;
