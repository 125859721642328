import { Image } from 'react-bootstrap'
import whatslogo from '../../assets/ico32/whatsapp32_1.png';


function Mycontactbar() {
    return (
        <>
        <div className='row'>
            <div className='col-12 text-right'>
            <Image src={whatslogo} /> 0723 550 723
            </div>
        </div>

    </>
    );
}

export default Mycontactbar;