import { Navbar, Nav, Image } from 'react-bootstrap';
import mylogo from '../../assets/steliana_ciolacu_asigurari.jpg';

function Mynavbar() {
    return(
    <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
  <Navbar.Brand href="/"><Image src={mylogo} roundedCircle height='35px' /> myasig.ro</Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="mr-auto">
      <Nav.Link href="#features">Sanatate</Nav.Link>
      <Nav.Link href="#pricing">Viata</Nav.Link>
      <Nav.Link href="#pricing">Locuinte</Nav.Link>
      <Nav.Link href="#pricing">Economisire</Nav.Link>
      <Nav.Link href="#pricing">Protectie</Nav.Link>
      <Nav.Link href="#pricing">Pensii</Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
);
}

export default Mynavbar;